<template>
  <div class="print-order px-2 mx-0 pagebreak">
    <div class="d-flex justify-content-between">
      <span>شماره سفارش: {{ order.id }}</span>
      <span>تاریخ: {{ moment(order.created_at).format("HH:mm YYYY/MM/DD") }}</span>
    </div>
    <table class="table print-customer-table  mt-2 mb-1">
      <tbody>
        <tr v-if="settings != '' " class="print-order-shop ">
          <td class="print-order-item-title ">
            <span class="text-center">
              فرستنده
            </span>
          </td>
          <td class="print-order-item-info text-right" style="padding-right:5px!important">
            <div
              class="print-order-item-address pb-lg-1 pt-1 align-items-center"
            >
              <div style="flex-shrink: 0">
                <span class="font-weight-bold fontsize15 mb-0">
                  عنوان :
                </span>
                <span class="mb-0 pr-1 fontsize14 pl-2">
                 {{settings.filter(item => item.name == 'shop_name')[0].value}}
                </span>
                <div style="display: inline">
                  <span class="font-weight-bold fontsize15 mb-0" >
                    کدپستی :
                  </span>
                      <span class="mb-0 fontsize14 pr-1 pl-2">
                    {{settings.filter(item => item.name == 'shop_postal_code')[0].value}}
                  </span>
                </div>
              </div>
              <div>
                <span class="font-weight-bold fontsize15 mb-0">
                  آدرس :
                </span>
                <span class="mb-0 pr-1 fontsize14 pl-2">
                 {{settings.filter(item => item.name == 'shop_address')[0].value}} - {{settings.filter(item => item.name == 'shop_telephone')[0].value}}

                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr class="print-order-shop ">
          <td class="print-order-item-title ">
            <span class="text-center">
              گیرنده
            </span>
          </td>
          <td class="print-order-item-info px-1 text-right" style="line-height:20px!important;">
            <div class=" pt-0">
                <span class="font-weight-bold fontsize15 mb-0">
                  نام کامل : <span class="mb-0 pr-1 fontsize14 pl-2" style="font-weight: 500">
                  {{ address.first_name }} {{ address.last_name }}
                </span>
                </span>
            </div>
            <div
              class="d-flex print-order-item-address align-items-center"
            >
              <div>
                <span class="font-weight-bold fontsize15 mb-0 ">
                  آدرس :
                </span>
                <span class="mb-0 pr-1 fontsize14 pl-1">
                  {{ address.city.province ? address.city.province.name : "" }}
                  - {{ address.city ? address.city.name : "" }} -
                  {{ address.address }}
                </span>
              </div>
            </div>
            <div class="">
              <span class="font-weight-bold fontsize15 mb-0">
                کدپستی:
              </span>
              <span class="mb-0 fontsize14 pl-2">
                {{ address.postal_code }}
              </span>
              <span class="font-weight-bold fontsize15 mb-0">
                تلفن:
              </span>
              <span class="mb-0 fontsize14 pl-2">
                {{ address.mobile }}
              </span>
              <span v-if="invoice" class="print-order-invoice">
                <span class="font-weight-bold fontsize15 mb-0">
                  روش پرداخت:
                </span>
                <span
                  v-if="invoice.type == 'gateway'"
                  class="mb-0 fontsize14 pl-2"
                >
                  درگاه {{ invoicePayment ? invoicePayment.gateway_label : "" }}
                </span>
                <span
                  v-else-if="invoice.type == 'wallet'"
                  class="mb-0 fontsize14 pl-2"
                >
                  کیف پول
                </span>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between mt-3">
      <span>شماره سفارش: {{ order.id }}</span>
      <span>{{ address.first_name }} {{ address.last_name }} - {{ address.mobile }}</span>
      <span>تاریخ: {{ moment(order.created_at).format("HH:mm YYYY/MM/DD") }}</span>
    </div>
    <b-table
    responsive
      :items="order.active_items"
      :fields="fields"
        bordered
      class="table print-customer-order-table mb-1"
    >
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(product)="data">
        <p style="line-height:2" class="mb-0">{{ data.item.product.title }}
          <span v-if="data.item.extra.color || data.item.extra.attributes != ''" class="mr-1"> </span>
          <span v-if="data.item.extra.color != null">
            رنگ : {{ data.item.extra.color }}
            {{
              data.item.extra.color && data.item.extra.attributes != ""
                  ? " | "
                  : ""
            }}
          </span>
          <span v-if="data.item.extra.attributes != ''">
            <span
                v-for="(attr, index) in data.item.extra.attributes"
                :key="index"
            >
              {{ attr.label + ":" + attr.pivot.value }}
              {{ index != data.item.extra.attributes.length - 1 ? " | " : "" }}
            </span>
          </span>
        </p>
      </template>
      <template v-slot:cell(amount)="data">
        <span v-if="data.item.amount != 0"
          >{{ (data.item.amount + data.item.discount_amount) | price }}
        </span>
        <span v-else>0</span>
      </template>
      <template v-slot:cell(quantity)="data">
        <span style="font-weight: bold">{{ (data.item.quantity) }}</span>
      </template>
      <template v-slot:cell(discount_amount)="data">
        <span v-if="data.item.discount_amount">{{ data.item.discount_amount | price }}</span>
        <span v-else>ندارد</span>
      </template>
      <template v-slot:cell(total_price)="data">
        <span v-if="data.item.amount != 0"
          >{{ (data.item.amount * data.item.quantity) | price }}
        </span>
        <span v-else>0</span>
      </template>
    </b-table>
    <div class="border p-2 d-flex justify-content-between" style="margin-bottom: 10px">
<!--      <div>-->
<!--        <span class="font-weight-bold">مبلغ تخفیف:</span> {{ total | price }} تومان-->
<!--      </div>-->
      <div>
        <span class="font-weight-bold">مبلغ فاکتور:</span> {{ total | price }} تومان
      </div>
      <div>
        <span class="font-weight-bold">هزینه حمل و نقل:</span> <template v-if="order.shipping_amount === 0">رایگان</template>
        <template v-else>{{ order.shipping_amount | price }} تومان</template>
      </div>
      <div v-if="totalDiscount">
        <span class="font-weight-bold">کل تخفیف:</span>
        <template>{{ totalDiscount | price }} تومان</template>
      </div>
      <div>
        <span class="font-weight-bold">جمع کل پرداختی:</span> {{ totalPrice | price }} تومان
      </div>
      <table style="display: none;width:400px" class="table print-order-total-table text-right">
        <tbody>
          <tr class="print-order-shop ">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ کل</span>
            </td>
            <td class="">{{ total | price }} تومان</td>
          </tr>
          <tr class="print-order-shop ">
            <td class="py-2">
              <span class="font-weight-bold px-2">هزینه حمل و نقل</span>
            </td>
            <td class="">
              <span v-if="order.shipping_amount != 0">
                {{ order.shipping_amount | price }} تومان
              </span>
              <span v-else class="">
                رایگان
              </span>
            </td>
          </tr>
          <tr v-if="order.discount_amount" class="print-order-shop ">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ تخفیف</span>
            </td>
            <td class="">
              <span v-if="order.discount_amount != 0">
                {{ order.discount_amount | price }} تومان
              </span>
              <span v-else class="">
                0
              </span>
            </td>
          </tr>
          <tr class="print-order-shop ">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ پرداختی</span>
            </td>
            <td class="">{{ totalPrice | price }} تومان</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center print-order-btn mb-4">
      <button class="btn btn-print text-white" @click="orderPrint()">
        چاپ فاکتور
      </button>
      <!-- <button class="btn btn-print text-white mr-1">بازگشت</button> -->
    </div>
  </div>
</template>
<script>
import OrderPrintItemImpl from '@@/core/components/order/OrderPrintItem/OrderPrintItemImpl'
export default {
  extends: OrderPrintItemImpl
}
</script>
<style scoped src="@@/core/components/order/OrderPrintItem/OrderPrintItemStyle.css"></style>
