<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">لیست فروش 
          <span v-if="sumSales">
          (
            مجموع فروش :
            <strong>
            {{ sumSales | price }}

</strong>
            تومان
            )
          </span>
          </h5>
        <div style="flex:1"></div>
        <div class="d-flex" style="gap: 0.5rem">
          <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'" class="mr-2">
            <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
          </b-button>
          <Refresh />
          <Back url="/admin/shop" />
        </div>
      </div>
      <div class="card-body">
        <b-collapse id="filter" class="mt-2 mx-5">
          <b-card>
            <form @submit.prevent="filterItem()">
              <div class="row mt-5">
                
                <b-form-group  v-if="hasPermission('read_customer')&&admins.length" class="col-md-12">
                  <multiselect
                      placeholder="انتخاب ادمین"
                      v-model="filter.admin_id"
                      open-direction="bottom"
                      :options="admins"
                      label="name"
                      track-by="id"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :disabled="disabled"
                  >
                    <template slot="noOptions">
                      موردی یافت نشد
                    </template>
                    <template slot="noResult">
                      موردی یافت نشد
                    </template>
                  </multiselect>
                </b-form-group>
                <b-form-group class="col-md-6">
                  <b-form-input :disabled="disabled" placeholder="شناسه" v-number v-model="filter.id">
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6">
                  <b-form-input :disabled="disabled" placeholder="کد رهگیری" v-number v-model="filter.tracking_code">
                  </b-form-input>
                </b-form-group>
                <b-form-group v-if="hasPermission('read_customer')" class="col-md-6">
                  <CustomerSearch v-model="filter.customer" :allow-empty="true" :show-label="false"/>
                </b-form-group>
                <b-form-group  v-if="hasPermission('read_customer')&&categories.length" class="col-md-6">
                  <multiselect
                      placeholder="دسته بندی"
                      v-model="filter.category_id"
                      open-direction="bottom"
                      :options="categories"
                      label="title"
                      track-by="id"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :disabled="disabled"
                  >
                    <template slot="noOptions">
                      موردی یافت نشد
                    </template>
                    <template slot="noResult">
                      موردی یافت نشد
                    </template>
                  </multiselect>
                </b-form-group>
                <b-form-group v-if="widgetData.products" class="col-md-3">
                  <multiselect
                      placeholder="محصول"
                      v-model="filter.product_id"
                      open-direction="bottom"
                      :options="products"
                      label="title"
                      track-by="id"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :disabled="disabled"
                  >
                    <template slot="noOptions">
                      موردی یافت نشد
                    </template>
                    <template slot="noResult">
                      موردی یافت نشد
                    </template>
                  </multiselect>
                </b-form-group>
                <!-- <b-form-group class="col-md-3">
                  <multiselect
                      placeholder="وضعیت"
                      v-model="filter.status"
                      open-direction="bottom"
                      :options="statusFilterOptions"
                      label="title"
                      track-by="value"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :disabled="disabled"
                  >
                    <template slot="noOptions">
                      موردی یافت نشد
                    </template>
                    <template slot="noResult">
                      موردی یافت نشد
                    </template>
                  </multiselect>
                </b-form-group> -->
                <b-form-group class="col-md-6">
                  <date-picker
                      placeholder="از تاریخ"
                      v-model="filter.start_date"
                      format="YYYY-MM-DD"
                      display-format="jYYYY-jMM-jDD"
                  />
                </b-form-group>
                <b-form-group class="col-md-6">
                  <date-picker
                      placeholder="تا تاریخ"
                      v-model="filter.end_date"
                      format="YYYY-MM-DD"
                      display-format="jYYYY-jMM-jDD"
                  />
                </b-form-group>
                <b-form-group class="col-md-12">
                  <ProductSearch ref="productSearch" :selectable="true"
                                 class="row" :disabled="disabled" @selectProduct="selectProduct" :show-label="false"
                                 @selectVariety="selectVariety"/>
                </b-form-group>
              </div>
              <div class="text-center mt-3">
                <button
                    :disabled="disabled"
                    type="submit"
                    class="btn btn-primary px-5"
                >
                  جستجو
                </button>
                <button
                    :disabled="disabled"
                    @click="resetFilter"
                    class="btn btn-warning px-5 mr-3"
                >
                  برداشتن فیلتر
                </button>
                <button
                    :disabled="disabled"
                    @click="$root.$emit('bv::toggle::collapse', 'filter')"
                    class="btn btn-secondary px-5 mr-3"
                >
                  بستن
                </button>
              </div>
            </form>
          </b-card>
        </b-collapse>
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}
            </template>
            <template v-slot:cell(more)="data">
              <div class="d-flex align-items-center justify-content-center">
                <router-link
                    :to="'/admin/shop/orders/' + data.item.id"
                    class="action-btns1  pt-1 px-1">
                  <i style="vertical-align: text-top" class="fe fe-eye   text-primary"></i>
                </router-link>
                <button
                    :style="!hasPermission('delete_mini_order') ? 'background: gray' : 'background: transparent'"
                    :disabled="!hasPermission('delete_mini_order') ? true : false"
                    class="action-btns1  pt-1 px-1 "
                    @click="deleteItem(data.item.id)"
                >
                  <i style="vertical-align: text-top" class="feather feather-trash-2 text-danger"></i>
                </button>
                <button v-if="data.item.description" @click="showDescription(data.item)" class="action-btns1  pt-1 px-1 ">
                  <i style="vertical-align: text-top" class="feather feather-clipboard"></i>
                </button>
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <span v-if="data.item.type == 'increment'" class="badge badge-success status">افزایش</span>
              <span v-else class="badge badge-danger status">کاهش</span>
            </template>
            <template v-slot:cell(total)="data">
              <span v-if="data.item.total >= 0" class="text-success">
                {{ data.item.total | price }}
              </span>
              <span v-else style="direction: ltr" dir="ltr" class="text-danger">-{{ data.item.total | price }}</span>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
                      @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
import CustomerSearch from "@@/core/components/shared/CustomerSearch/CustomerSearch";
import mixins from '@@/core/mixins/mixins'
import Loading from "@@/core/components/shared/Loading/Loading";
import {priceFilter} from "@@/core/libraries/filters";
import Back from '@@/core/components/shared/Back/Back'
import Refresh from "@@/core/components/shared/Refresh/Refresh";
import shopIndexMixin from "@@/core/pages/physicalShop/physicalShopMixin.js"
export default {
  name: "ShopIndex",
  mixins: [mixins,shopIndexMixin],
  components: {Refresh,  Loading, Back,ProductSearch,CustomerSearch
   },
  data() {
    return {
     
      url: 'admin/mini_orders',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'mini_order_items_count',
          label: 'تعداد آیتم ها'
        },
        {
          key: 'total',
          label: 'جمع کل'
        },
        {
          key: 'more',
          label: 'عملیات',
          formatter: priceFilter,
        },
        {
          key: 'created_at',
          label: 'تاریخ ایجاد'
        }
      ]
    }
  },
  mounted() {
    // this.loadItems()
  },
  
  methods: {
    showDescription(item) {
      window.swal({
        icon: "info",
        showConfirmButton: true,
        text: item.description,
        button: "بستن",
      })
    }
  }
}
</script>

<style scoped>
.order-product-img {
  max-width: 50px;
  margin: auto;
}

.table-title {
  font-weight: bold;
}
</style>
<style>
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
}

.card.little.online {
  background: linear-gradient(30deg, #c8ffc8 0%, #c8ffc8 35%, #e0ffe0 100%);
}

.card.little.physical {
  background: linear-gradient(30deg, #ffffe3 0%, #ffffbc 35%, #ffffc5 100%);
}

.card.little .card-header {
  font-weight: bold;
}

</style>
