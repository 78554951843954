<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">قیمت گذاری</h5>
    </div>
    <div class="card-body">
      <InputGroup label-padding="0px" col1="3" col2="9" label="هزینه ارسال (اختیاری)">
        <b-form-input :value="realValue.shippingPrice | priceinput" @input="value=>{realValue.shippingPrice = value !== '' ? +value.replace(/\D/g, '') : ''}"
                      type="text" placeholder="هزینه ارسال"/>
      </InputGroup>
      <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت واحد" required>
        <b-form-input :value="realValue.unitPrice | priceinput" @input="value=>{realValue.unitPrice = value !== '' ? +value.replace(/\D/g, '') : ''}"
                      type="text" placeholder="قیمت واحد"/>
      </InputGroup>
      <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت خرید">
        <b-form-input :value="realValue.purchasePrice | priceinput" @input="value=>{realValue.purchasePrice = value !== '' ? +value.replace(/\D/g, '') : ''}"
                      type="text" placeholder="قیمت خرید"/>
      </InputGroup>
      <InputGroup label-padding="0px" col1="3" col2="9" label="تخفیف">
        <Discount v-model="realValue" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductPricingImpl from '@@/core/components/product/small-cards/ProductPricing/ProductPricingImpl'
export default {
  extends: ProductPricingImpl
}
</script>

<style scoped>

</style>
