<template>
  <button :disabled="disabled" @click="download" class="btn btn-success">
    دانلود اکسل
  </button>
</template>

<script>
export default {
  name: "ExcelDownload",
  props: {
    url: String,
    use_param: {
      type: Boolean,
      default: false
      },
    param: {
      type: Object,
      default: function(){return {};}
    }
    
  },
  data() {
    return {
      percentCompleted: null,
      disabled: false,
    }
  },
  methods: {
    async download() {
      this.disabled = true
      try {
        const response = await this.$axios(this.url, {
          headers: {
            "Accept": "x-xlsx",
          },
          params: this.param,
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(progressEvent.lengthComputable);
            console.log(percentCompleted);

          }
        });
        this.disabled = false
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'output.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    },
  }
}
</script>

<style scoped>

</style>
