import {decapitalizeFirstLetter} from "@@/core/libraries/VueMethods";

const translations = {
  name: 'نام',
  code: 'کد',
  status: 'وضعیت',
  color: 'رنگ',
  provinces: 'استان',
  unit: 'واحد',
  symbol: 'نماد',
  precision: 'دقت',
  show_index : 'نمایش در صفحه اصلی',
  description: 'توضیح',
  brand: 'برند',
  city: 'شهر',
  province_id: 'استان',
  title: 'عنوان',
  post_category_id: 'دسته بندی',
  summary: 'خلاصه',
  body: 'متن',
  meta_description: 'توضیحات متا',
  special: 'ویژه',
  image: 'تصویر',
  username:'نام کاربری',
  password:'رمز عبور',
  mobile:'شماره تماس',
  role:'نقش',
  email:'ایمیل',
  admin:'مدیر',
  question:'سوال',
  answer:'پاسخ',
  faq:'سوال متداول',
  position:'جایگاه',
  label:'لیبل',
  key:'کلید',
  width:'عرض',
  height:'ارتفاع',
  discount: 'تخفیف',
  start_date: 'تاریخ شروع',
  end_date: 'تاریخ پایان',
  gift: 'هدیه',
  published_at: 'انتشار در',
  CustomerRole: 'نقش مشتری',
  see_expired: 'دیدن محصولات زمانبندی شده',
  full_name: 'نام کامل',
  national_code: 'کد ملی',
  seller: 'فروشنده',
  active: 'فعال',
  withdraw: 'برداشت',
  pending: 'در انتظار تکمیل',
  paid: 'پرداخت شده',
  canceled: 'لغو شده',
  published: 'انتشار یافته',
  tracking_code: 'کد رهگیری',
  card_number: 'شماره کارت',
  amount: 'مبلغ',
  province: 'استان',
  page_content:'محتوای صفحه'
  
}

export default function getTranslation(word) {
  if (typeof translations[word] !== 'undefined') {
    return translations[word];
  }
  if (typeof translations[decapitalizeFirstLetter(word)] !== 'undefined') {
    return translations[decapitalizeFirstLetter(word)];
  }

  return word;
}
