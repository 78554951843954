<template>
  <b-form-group :disabled="disabled" class="in-input" :class="type">
    <label
      @click="labelClicked"
      class="bv-no-focus-ring col-form-label pt-0 pb-0"
      slot="label"
      >{{ getTranslation(name)
      }}<span v-if="shouldShowRequired && required" class="text-danger">
        *</span
      ></label
    >
    <b-form-input v-if="type == 'password'" type="password" :name="name" />
    <b-form-input
      v-else-if="type == 'string'"
      :readonly="alwaysDisabled"
      type="text"
      v-model="realValue"
      :name="name"
    />
    <b-form-textarea
      v-else-if="type === 'textarea'"
      :id="uniqueId()"
      ref="textarea"
      v-model="realValue"
      :name="name"
    />
    <b-form-input
      v-else-if="type == 'integer'"
      :name="name"
      type="number"
      v-model="realValue"
    />
    <b-form-input
      v-else-if="type == 'email'"
      :name="name"
      type="email"
      v-model="realValue"
    />
    <b-form-input
      v-else-if="type == 'price'"
      :value="realValue | priceinput"
      :placeholder="label"
      @input="
        (value) => {
          realValue = value !== '' ? +value.replace(/\D/g, '') : '';
        }
      "
    />
    <b-form-input
      ref="numericInput"
      @input="changeStep"
      v-else-if="type == 'numeric'"
      :name="name"
      type="number"
      step="any"
      v-model="realValue"
    />
    <!-- <input type="checkbox" v-else-if="type== 'boolean'" v-model="realValue" :name="name"/> -->
    <template v-else-if="type == 'boolean'">
      <b-form-checkbox
        :id="name"
        v-model="realValue"
        value="1"
        unchecked-value="0"
      />
      <input type="hidden" :name="name" :value="realValue" />
    </template>
    <div class="color-container" v-else-if="type == 'color'">
      <input
        @click="$refs.colorPicker.click()"
        type="text"
        class="form-control"
        v-model="realValue"
      />
      <input
        ref="colorPicker"
        class="color-picker"
        type="color"
        :name="name"
        v-model="realValue"
      />
    </div>
    <template v-else-if="type === 'select'">
      <Multiselect
        v-model="realValue"
        open-direction="bottom"
        :options="items"
        :label="select_label || 'title'"
        track-by="id"
        placeholder="انتخاب کنید"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </Multiselect>
      <input type="hidden" :name="name" v-model="realValue.id" />
    </template>
    <textarea
      v-else-if="type === 'editor'"
      :id="id"
      ref="editor"
      type="text"
      :name="name"
      :value="value"
    />
    <template v-else-if="type === 'custom_select'">
      <Multiselect
        v-model="realValue"
        placeholder="انتخاب کنید"
        open-direction="bottom"
        :options="custom_selects"
        :label="'label'"
        track-by="value"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </Multiselect>
      <input type="hidden" :name="name" v-model="realValue.value" />
    </template>
    <template v-else-if="type === 'image'">
      <b-form-file
        placeholder="فایلی انتخاب نشده"
        type="file"
        class="form-control"
        :name="name"
      />
      <ImageHolder class="mt-2" v-if="value && value.url">
        <img :src="value.url" />
      </ImageHolder>
    </template>
    <template v-else-if="type === 'file'">
      <b-form-file
        placeholder="فایلی انتخاب نشده"
        type="file"
        class="form-control"
        :name="name"
      />
    </template>
    <template v-else-if="type === 'datetime'">
      <date-picker
        :format="options.format"
        :display-format="options.displayFormat"
        v-model="realValue"
        :type="options.type"
      />
      <input type="hidden" :name="name" :value="realValue" />
    </template>
    <template v-else-if="type === 'addableselect'">
      <TreeselectAddable
        @add-option="addOption"
        :options="selectOptions"
        placeholder="بنویسید"
        v-model="realValue"
      />
      <input
        type="hidden"
        :name="name + '[]'"
        :value="value"
        v-for="value in realValue"
      />
    </template>
  </b-form-group>
</template>

<script>
import { uniqueId, loadCkeditor } from "@@/core/libraries/VueMethods";
import ImageHolder from "@@/core/components/shared/ImageHolder/ImageHolder";
import _getTranslation from "@@/core/libraries/form/translations";
import Multiselect from "vue-multiselect";
import {
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import TreeselectAddable from "@@/core/components/product/shared/TreeselectAddable/TreeselectAddable";
import DatePicker from "vue-persian-datetime-picker";

export default {
  name: "InputText",
  components: {
    ImageHolder,
    Multiselect,
    TreeselectAddable,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormFile,
    DatePicker,
  },
  props: {
    alwaysDisabled: String,
    type: String,
    name: String,
    required: { default: false, type: Boolean },
    custom_selects: Array,
    value: {
      type: [String, Boolean, Object, Number],
    },
    label: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
    },
    select_label: String,
    id: [String, Number],
    options: Object,
  },
  data() {
    return {
      realValue: this.value,
      selectOptions: [],
    };
  },
  watch: {
    realValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.realValue = newVal;
    },
  },
  computed: {
    shouldShowRequired() {
      return this.type != "boolean";
    },
  },
  methods: {
    uniqueId,
    addOption(opt) {
      this.options.push(opt);
    },
    changeStep(value) {
      let input = this.$refs.numericInput.$el;
      let step;
      if (!value.includes(".")) {
        step = 1;
      } else {
        value = "" + value;
        step = Math.pow(10, -1 * (value.length - 1 - value.indexOf(".")));
      }
      input.step = step;
    },
    getTextareaValue() {
      // return getData()
    },
    getTranslation(word) {
      if (this.label) {
        return this.label;
      }
      return _getTranslation(word);
    },
    labelClicked() {
      if (this.mode == "boolean") {
        this.realValue = !this.realValue;
      }
    },
  },
  created() {
    // if (this.textarea) {
    //   this.$root.loadCkedtor(this.$refs.textarea.id)
    // }
    if (this.type == "numeric") {
      this.changeStep(this.value);
    }
    if (this.type === "editor") {
      if (!this.ckeditorLoaded) {
        this.ckeditorLoaded = true;
        let script = document.createElement("script");
        script.src = "/assets/editors/ckeditor/ckeditor.js";
        document.head.append(script);
      }
      setTimeout(() => {
          window.CKEDITOR.replace(this.id);
      }, 1500);
    }
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  width: 30px;
}

.in-input.boolean label {
  cursor: pointer;
}

.in-input.boolean input[type="checkbox"] {
  cursor: pointer;
}

input[type="color"] {
  background: transparent;
  padding: 4px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: 1px #e4e4e4 solid;
}

.color-container {
  position: relative;
  overflow: hidden;
}

.color-picker {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
<style>
/* .in-input.boolean .col-form-label {
  display: contents;
} */
</style>
