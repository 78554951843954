import {uniqueId} from "@@/core/libraries/VueMethods";
import dayjs from "dayjs";
import getTranslation from "@@/core/libraries/form/translations";

export default class input {
  constructor(name, rulesString, data = {}) {
    this.initialize();
    this.name = name;
    let rules = Array.isArray(rulesString) ? rulesString : rulesString.split('|');
    rules.forEach(rule => {
      if (typeof rule === 'object') {
        return
      }

      switch (rule) {
        case 'required':
          this.required = true;
          break;
        case 'string':
          this.value = '';
          // hardCode sorry
          this.type = this.name!='page_content'?'string':'editor';
          if(this.name=="page_content"){
          this.id = uniqueId()
            
          }
          break;
        case 'integer':
          this.type = 'integer';
          break;
        case 'textarea':
          this.type = 'textarea';
          break;
        case 'boolean':
          this.type = 'boolean';
          this.value = "1";
          break;
        case 'color':
          this.type = 'color'
          this.value = '#8c8c8c'
          break;
        case 'numeric' :
          this.type = 'numeric'
          this.value = '1'
          break;
        case 'image' :
          this.type = 'image'
          break;
        case 'email' :
          this.type = 'email'
          break;
        case 'password' :
          this.type = 'password'
          break;
        case 'editor':
          this.type = 'editor';
          this.value = ''
          this.id = uniqueId()
          break
        case 'datetime':
          this.type = 'datetime';
          this.value = ''
          this.id = uniqueId()
          break;
        case 'file':
          this.type = 'file';
          this.value = ''
          this.id = uniqueId()
          break;
        case 'addableselect':
          this.type = 'addableselect';
          this.value = []
          this.id = uniqueId()
          break;
        default:
          if (rule.startsWith('date_format:')) {
            this.type = 'datetime';
            this.value = ''
            let format = rule.split('date_format:')[1];
            this.options.type = format.includes(' ') ? 'datetime': '';
            [this.options.format, this.options.displayFormat] = this.resolveDateTimeFormat(format)
          }
      }
      if (typeof rule === 'string') {
        if (rule === 'disabled') {
          this.alwaysDisabled = true
        }
        if (rule.startsWith('label')) {
          let labelArr = rule.split(':');
          if (labelArr.length == 2 && labelArr[0] == 'label'){
            this.label = labelArr[1];
          }
        }
        if (rule.startsWith('select_label')) {
          let labelArr = rule.split(':');
          if (labelArr.length == 2 && labelArr[0] == 'select_label'){
            this.select_label = labelArr[1];
          }
        }
        else if (rule.startsWith('select')) {
          let labelArr = rule.split(':');
          if (labelArr.length == 2 && labelArr[0] == 'select'){
            this.type = 'select';
            if (data.selects && data.selects[labelArr[1]]) {
              this.items = data.selects[labelArr[1]];
            } else {
              this.items = [];
            }
          }
        } else if (rule.startsWith('custom_select')) {
          let labelArr = rule.split(':');
          if (labelArr.length == 2 && labelArr[0] == 'custom_select'){
            this.customSelects = []
            let vals = labelArr[1].split(',');
            vals.forEach(v => {
              this.customSelects.push({value: v, label: getTranslation(v)})
            })
            this.type = 'custom_select';
          }
        }
      }

    })
    if (typeof data.value !== 'undefined') {
      if (this.type == 'boolean') {
        this.value = data.value ? '1' : '0'
      } else if (this.type === 'select') {
        if (isNaN(data.value)) {
          this.value = data.value;
        } else {
          this.value = this.items.find((item) => {
            return item.id === data.value;
          })
        }
      } else if (this.type == 'custom_select') {
        this.value = {value: data.value, label: getTranslation(data.value)}
      } else if (this.type == 'datetime') {
        this.value = data.value ? dayjs(data.value).clone().locale('en').format(this.options.format) : '';
      } else {
        this.value = data.value
      }

      this.defaultValue = this.value
    }
  }

  initialize() {
    this.type = null;
    this.required = false;
    this.value = '';
    this.label = null;
    this.options = {};
  }

  resetValue() {
    if (['string', 'textarea'].includes(this.type))
      this.value = ''
  }

  isValid() {
    return Boolean(this.type) && !this.name.includes('.');
  }

  resolveDateTimeFormat(originalFormat) {
    let displayFormat = originalFormat;
    let format = originalFormat;
    let replaces = {
      'Y': ['YYYY', 'jYYYY'],
      'm': ['MM', 'jMM'],
      'd': ['DD', 'jDD'],
      'H': ['HH'],
      'i': ['mm']
    }
    Object.entries(replaces).forEach(([key, _replace]) => {
      format = format.replace(key, _replace[0]);
      displayFormat = displayFormat.replace(key, _replace[1] || _replace[0]);
    })

    return [format, displayFormat];
  }
}
